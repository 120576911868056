/**
 * @generated SignedSource<<e3d5869d3d53cd67f122b82528097590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery$variables = {
  id: string;
};
export type MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery$data = {
  readonly organization: {
    readonly id?: string;
    readonly slack?: {
      readonly workspace: {
        readonly name: string | null;
        readonly url: string | null;
      } | null;
    } | null;
    readonly user?: {
      readonly avatar: string;
      readonly email: string;
      readonly firstName: string | null;
      readonly fullName: string;
      readonly lastName: string | null;
    } | null;
  } | null;
};
export type MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery = {
  response: MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery$data;
  variables: MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "user",
  "args": null,
  "concreteType": "PrimarySlackOrganizationMembershipConnectionUser",
  "kind": "LinkedField",
  "name": "primarySlackConnectionUserInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SlackWorkspace",
  "kind": "LinkedField",
  "name": "workspace",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackConnection",
                "kind": "LinkedField",
                "name": "slack",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackConnection",
                "kind": "LinkedField",
                "name": "slack",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "610b57c607bf07717083634151698135",
    "id": null,
    "metadata": {},
    "name": "MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery",
    "operationKind": "query",
    "text": "query MemberOnboardingSlackRequestInvite_PrimarySlackConnectionQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      user: primarySlackConnectionUserInfo {\n        fullName\n        firstName\n        lastName\n        email\n        avatar\n      }\n      slack {\n        workspace {\n          name\n          url\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5dc0770e795b44e0c55aca6ce43a3e36";

export default node;
