import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import {
  MembershipPlanDetailsCardFragment$data,
  MembershipPlanDetailsCardFragment$key,
} from "@/membership-plan/register/__generated__/MembershipPlanDetailsCardFragment.graphql"
import {
  getFrequencyLabel,
  getPricingKindLabel,
  usePricingDisplayValue,
} from "@/pricing/pricingUtils"
import ProductBadgeStack from "@/product/card/ProductBadgeStack"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto, { CoverPhotoSkeleton } from "@components/cover-photo/CoverPhoto"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoChipSkeleton,
  DiscoIcon,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MembershipPlanDetailsCardProps extends TestIDProps {
  membershipPlanKey: MembershipPlanDetailsCardFragment$key
  onClick: (membershipPlan: MembershipPlanDetailsCardFragment$data) => void
  className?: string
  hideDescription?: boolean
  isDashboardBlock?: boolean
}

function MembershipPlanDetailsCard({
  testid = "MembershipPlanDetailsCard",
  membershipPlanKey,
  onClick,
  className: customClassName,
  hideDescription = false,
  isDashboardBlock = false,
}: MembershipPlanDetailsCardProps) {
  const activeOrganization = useActiveOrganization()
  const theme = useTheme()

  const membershipPlan = useFragment<MembershipPlanDetailsCardFragment$key>(
    graphql`
      fragment MembershipPlanDetailsCardFragment on Product
      @argumentDefinitions(
        hideDrafts: { type: "Boolean!" }
        hideNonPublic: { type: "Boolean!" }
      ) {
        id
        slug
        cover
        name
        description
        ...ProductBadgeStackFragment_membership
          @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
        pricing {
          amountCents
          frequency
          kind
          ...pricingUtils_usePricingDisplayValue
        }
      }
    `,
    membershipPlanKey
  )

  const { pricing, cover, name, description } = membershipPlan

  const fullPricingValue = usePricingDisplayValue({
    pricingKey: pricing,
    currency: activeOrganization?.currency,
    length: "short",
  })

  const pricingValue = fullPricingValue.split("/")[0]
  const frequency = getFrequencyLabel(pricing?.frequency, "short", true)

  const alreadyOnPlan = Boolean(
    activeOrganization?.viewerMembership &&
      activeOrganization?.viewerMembershipPlan?.id === membershipPlan.id
  )
  const disablePlans =
    activeOrganization?.visibility === "private" && !activeOrganization.viewerMembership

  const classes = useStyles({ disabled: alreadyOnPlan, isDashboardBlock })

  if (!membershipPlan) return null

  return (
    <DiscoContainerButton
      testid={`${testid}.${membershipPlan.name}`}
      className={classNames(classes.card, customClassName)}
      onClick={() => onClick(membershipPlan)}
      disabled={alreadyOnPlan}
      tooltip={disablePlans ? "You need an invite to join this community." : undefined}
      tooltipWapperClassName={classes.cardTooltipWrapper}
    >
      {/* Cover */}
      <CoverPhoto
        coverPhoto={cover || defaultThumbnail}
        customClassName={classes.cover}
      />

      <div className={classes.details}>
        {/* Name */}
        <DiscoText variant={"body-md-600"}>{name}</DiscoText>
        {isDashboardBlock && (
          <div>
            <div>
              <DiscoText
                variant={"heading-md-700"}
                color={"groovy.neutral.700"}
                component={"span"}
              >
                {pricingValue}
              </DiscoText>
              {frequency.length > 0 && (
                <DiscoText
                  variant={"body-xs"}
                  color={
                    theme.palette.type === "dark"
                      ? "groovy.onDark.200"
                      : "groovy.neutral.400"
                  }
                >
                  {`/${frequency}`}
                </DiscoText>
              )}
            </div>
            <DiscoText
              variant={"body-xs"}
              color={
                theme.palette.type === "dark" ? "groovy.onDark.200" : "groovy.neutral.400"
              }
            >
              {getPricingKindLabel(pricing!.kind, pricing?.frequency)}
            </DiscoText>
          </div>
        )}

        {!hideDescription && description && (
          <DiscoText truncateText={2}>{description}</DiscoText>
        )}

        <div className={classes.bottomDetails}>
          <div className={classes.productDetails}>
            {/* Included products */}
            <ProductBadgeStack
              membershipProductKey={membershipPlan}
              hideBorder={isDashboardBlock}
            />

            {!isDashboardBlock && (
              <div>
                {/* Pricing Details */}
                <DiscoText
                  variant={"body-lg-600"}
                  color={pricing?.kind === "free" ? "primary.main" : "groovy.neutral.700"}
                >
                  {fullPricingValue}
                </DiscoText>
                <DiscoText
                  variant={"body-xs"}
                  color={
                    theme.palette.type === "dark"
                      ? "groovy.onDark.200"
                      : "groovy.neutral.400"
                  }
                >
                  <div className={classes.pricing}>
                    {pricing?.kind === "recurring" && (
                      <DiscoIcon
                        icon={"repeat-credit-card"}
                        color={theme.palette.groovy.grey[400]}
                      />
                    )}
                    {getPricingKindLabel(pricing!.kind, pricing?.frequency)}
                  </div>
                </DiscoText>
              </div>
            )}
          </div>
          {/* CTA */}
          <DiscoButton
            data-testid={`${testid}.select-button`}
            width={"100%"}
            onClick={() => onClick(membershipPlan)}
            disabled={alreadyOnPlan || disablePlans}
            variant={theme.palette.type === "dark" ? "contained" : "outlined"}
            color={theme.palette.type === "dark" ? "white" : "grey"}
          >
            {"Choose Plan"}
          </DiscoButton>
        </div>
      </div>
    </DiscoContainerButton>
  )
}

type StyleProps = {
  disabled?: boolean
  isDashboardBlock?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: ({ disabled }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    boxShadow: theme.palette.groovyDepths.xs,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderDashboardCard,
    backgroundColor: theme.palette.background.paper,

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
      cursor: "pointer",
    },

    ...styleIf(disabled, {
      opacity: 0.5,
    }),
  }),
  cover: {
    borderRadius: 0,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderTopLeftRadius: theme.measure.borderRadius.big,
  },
  details: ({ isDashboardBlock }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    padding: theme.spacing(1.5),
    gap: theme.spacing(1.5),

    ...styleIf(!isDashboardBlock, {
      alignItems: "center",
      textAlign: "center",
      gap: theme.spacing(2.5),
    }),

    ...styleIf(isDashboardBlock, {
      gap: theme.spacing(2.5),
    }),
  }),
  productDetails: ({ isDashboardBlock }: StyleProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    ...styleIf(!isDashboardBlock, {
      flexDirection: "column",
      gap: theme.spacing(2.5),
    }),

    ...styleIf(isDashboardBlock, {
      marginBottom: theme.spacing(2.5),
    }),
  }),
  bottomDetails: ({ isDashboardBlock }: StyleProps) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
    flexDirection: "column",

    ...styleIf(!isDashboardBlock, {
      gap: theme.spacing(2.5),
    }),
  }),
  pricing: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  cardTooltipWrapper: {
    display: "flex",
  },
}))

export function MembershipPlanDetailsCardSkeleton({
  isDashboardBlock,
}: Partial<MembershipPlanDetailsCardProps>) {
  const classes = useStyles({ disabled: false, isDashboardBlock })
  return (
    <div className={classes.card} style={{ width: "312px" }}>
      <CoverPhotoSkeleton className={classes.cover} />

      <div className={classes.details}>
        <DiscoTextSkeleton
          variant={"heading-sm"}
          marginTop={2.5}
          marginBottom={0.5}
          width={"40%"}
        />

        <DiscoTextSkeleton
          variant={"body-sm"}
          marginBottom={2.5}
          align={"center"}
          width={"100%"}
        />

        <div className={classes.bottomDetails}>
          <DiscoChipSkeleton width={"20%"} />

          <DiscoTextSkeleton variant={"body-lg-600"} width={"35%"} marginTop={2.5} />
          <DiscoTextSkeleton
            variant={"body-xs-500"}
            color={"groovy.grey.400"}
            marginBottom={2.5}
            width={"40%"}
          />

          <DiscoButtonSkeleton width={"100%"} />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton<MembershipPlanDetailsCardProps>({
  component: observer(MembershipPlanDetailsCard),
  skeleton: MembershipPlanDetailsCardSkeleton,
})
