/**
 * @generated SignedSource<<e8cd4f5a8bf844f85382cd6e93eaf325>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LandingPageKind = "community_home" | "community_marketing" | "course_landing" | "membership_plan" | "pathway_landing" | "%future added value";
export type LandingPageMode = "external" | "internal" | "none" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCommunityLandingPagesFragment$data = {
  readonly landingPages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly dashboard: {
          readonly id: string;
        } | null;
        readonly id: string;
        readonly kind: LandingPageKind;
        readonly mode: LandingPageMode;
      };
    }>;
  } | null;
  readonly " $fragmentType": "useCommunityLandingPagesFragment";
};
export type useCommunityLandingPagesFragment$key = {
  readonly " $data"?: useCommunityLandingPagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCommunityLandingPagesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCommunityLandingPagesFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kinds",
          "value": [
            "community_home",
            "community_marketing"
          ]
        }
      ],
      "concreteType": "LandingPageNodeConnection",
      "kind": "LinkedField",
      "name": "landingPages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LandingPageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LandingPage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Dashboard",
                  "kind": "LinkedField",
                  "name": "dashboard",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "landingPages(kinds:[\"community_home\",\"community_marketing\"])"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "b2bc8d2bbfa6769f91783c772bacf84f";

export default node;
