import useBrainSearchBot from "@/admin/integrations/chat-bot/util/useBrainSearchBot"
import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isBrainSearchPath } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface BrainSearchNavButtonProps
  extends TestIDProps,
    Pick<CommunityAppBarProps, "variant"> {}

function BrainSearchNavButton({
  testid = "BrainSearchNavButton",
  variant,
}: BrainSearchNavButtonProps) {
  const brainSearchBot = useBrainSearchBot()
  const isOnBrainSearchPath = isBrainSearchPath(location.pathname)

  if (!brainSearchBot) return null
  if (brainSearchBot.status !== "active" && !brainSearchBot.isPreviewing) {
    return null // Don't show in sidebar if bot is not active AND they are not previewing the app
  }

  return (
    <GlobalNavButton
      testid={testid}
      tooltip={brainSearchBot.name}
      isActive={isOnBrainSearchPath}
      variant={variant}
      to={ROUTE_NAMES.BRAIN.ROOT}
    >
      <DiscoIcon icon={"stars"} active={isOnBrainSearchPath} />
    </GlobalNavButton>
  )
}

export default BrainSearchNavButton
