/**
 * @generated SignedSource<<8b55056f2c3107b3808a673a516cb56b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnsplashImageSearchInput = {
  organizationId: string;
  productId?: string | null;
  search: string;
};
export type UnsplashSearchResultsQuery$variables = {
  input: UnsplashImageSearchInput;
};
export type UnsplashSearchResultsQuery$data = {
  readonly response: {
    readonly data: ReadonlyArray<{
      readonly downloadUrl: string;
      readonly id: string;
      readonly imageThumbUrl: string;
      readonly imageUrl: string;
      readonly photoName: string;
      readonly photographerName: string;
      readonly photographerUrl: string;
    }> | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type UnsplashSearchResultsQuery = {
  response: UnsplashSearchResultsQuery$data;
  variables: UnsplashSearchResultsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnsplashImageSearchResponse",
    "kind": "LinkedField",
    "name": "searchUnsplashImages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnsplashImageSearchResultValue",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageThumbUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downloadUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photographerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photographerUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsplashSearchResultsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsplashSearchResultsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6092e8f65aef3e69a9c1986bb86cc4e7",
    "id": null,
    "metadata": {},
    "name": "UnsplashSearchResultsQuery",
    "operationKind": "query",
    "text": "query UnsplashSearchResultsQuery(\n  $input: UnsplashImageSearchInput!\n) {\n  response: searchUnsplashImages(input: $input) {\n    data {\n      id\n      imageThumbUrl\n      imageUrl\n      downloadUrl\n      photoName\n      photographerName\n      photographerUrl\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1d378bc4a35d069c4da10c69450ed06";

export default node;
