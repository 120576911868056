import { AssetFileType } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import MediaFileListTemplate from "@/media/add/list/MediaFileListTemplate"
import { getMediaLibraryPopoverElement } from "@/media/add/UploadMediaModal"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, {
  FileDropzoneSuggestedDimensions,
  MimeTypes,
} from "@components/dropzone/FileDropzone"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton, DiscoDropdown, DiscoLink, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoTableSearchInput from "@disco-ui/table/header/search/DiscoTableSearchInput"
import DiscoTableSortDropdown, {
  DiscoTableSortDirection,
  DiscoTableSortOption,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { useState } from "react"
import { DropzoneOptions } from "react-dropzone"

type Props = {
  onUpload: (result: MediaResult, file: File) => void
  onMediaSelect: (result: MediaResult) => void
  cropperProps?: ImageCropModalCropperProps
  dropzoneOptions: Omit<DropzoneOptions, "onDrop">
  showSupportedFiles?: boolean
  suggestedDimensions?: FileDropzoneSuggestedDimensions
  enforceSuggestedDimensions?: boolean
  message?: React.ReactElement | string
  allowedFileTypes?: AssetFileType[]
  canViewAssets?: boolean
  onClose: () => void
  includeOrganizationIdOnAsset?: boolean
  includeInMediaLibrary?: boolean
  allowedMimeTypes?: MimeTypes[]
}
type SelectedFileTypeOption = { type: AssetFileType | ""; title: string }

const FILE_TYPE_OPTIONS: SelectedFileTypeOption[] = [
  {
    type: "",
    title: "All Formats",
  },
  {
    type: "image",
    title: "Images",
  },
  {
    type: "video",
    title: "Videos",
  },
  {
    type: "document",
    title: "Documents",
  },
]

const ORDER_BY_OPTIONS: DiscoTableSortOption[] = [
  {
    value: "name",
    title: "Name",
    context: {
      order: { field: "name" },
    },
  },
  {
    value: "size",
    title: "Size",
    context: {
      order: { field: "size" },
    },
  },
  {
    value: "times_used",
    title: "Times Used",
    context: {
      order: { field: "times_used" },
    },
  },
  {
    value: "upload_date",
    title: "Upload Date",
    context: {
      order: { field: "upload_date" },
    },
  },
]

export type MediaLibraryToolbarState = DiscoTableToolbarStateWithSort & {
  fileTypeOption: SelectedFileTypeOption
}

function MediaLibraryFileFields({
  onUpload,
  onMediaSelect,
  cropperProps,
  dropzoneOptions,
  showSupportedFiles,
  suggestedDimensions,
  enforceSuggestedDimensions,
  message,
  allowedFileTypes,
  canViewAssets = true,
  onClose,
  includeOrganizationIdOnAsset,
  includeInMediaLibrary,
  allowedMimeTypes,
}: Props) {
  const [toolbarState, setToolbarState] = useState<MediaLibraryToolbarState>({
    search: "",
    fileTypeOption:
      // If allowedFileTypes has only one item, use that item as the default fileTypeOption
      // Otherwise, use "All Formats" for simplicity sake
      allowedFileTypes?.length === 1
        ? FILE_TYPE_OPTIONS.find((o) => o.type === allowedFileTypes[0])!
        : FILE_TYPE_OPTIONS[0],
    sort: {
      id: "none",
      order: { field: "upload_date" },
      title: "Sort",
    },
    direction: "DESC",
    viewType: "grid",
  })
  const classes = useStyles()

  // If the user has selected a specific file type or "All Formats", show the file type
  const isAllFormatsSelected = toolbarState.fileTypeOption.title === "All Formats"
  const showImageOption =
    toolbarState.fileTypeOption.type === "image" || isAllFormatsSelected
  const showVideoOption =
    toolbarState.fileTypeOption.type === "video" || isAllFormatsSelected
  const showDocumentOption =
    toolbarState.fileTypeOption.type === "document" || isAllFormatsSelected

  const { viewType } = toolbarState
  // Check if the file type is allowed
  // If allowedFileTypes is undefined, then all file types are allowed
  const shouldCheckAllowedFileType = Array.isArray(allowedFileTypes)
  const isImageAllowed = shouldCheckAllowedFileType
    ? allowedFileTypes.includes("image")
    : true
  const isVideoAllowed = shouldCheckAllowedFileType
    ? allowedFileTypes.includes("video")
    : true
  const isDocumentAllowed = shouldCheckAllowedFileType
    ? allowedFileTypes.includes("document")
    : true

  // Include the "All Formats" option + allowed file options
  const dropdownOptions = allowedFileTypes
    ? FILE_TYPE_OPTIONS.filter((o) => o.type === "" || allowedFileTypes.includes(o.type))
    : FILE_TYPE_OPTIONS

  const fileDropZone = (
    <FileDropzone
      className={classes.fileDropzone}
      variant={"upload-icon"}
      testid={"MediaLibraryFileFields"}
      dropzoneOptions={dropzoneOptions}
      showSupportedFiles={showSupportedFiles}
      suggestedDimensions={suggestedDimensions}
      enforceSuggestedDimensions={enforceSuggestedDimensions}
      message={
        message || (
          <DiscoText variant={"body-sm-600"}>
            {"Drop your media here or "}
            <DiscoLink>{"browse"}</DiscoLink>
          </DiscoText>
        )
      }
      onUpload={(r, f) => {
        onUpload(r, f)
        onClose()
      }}
      cropperProps={cropperProps}
      parentSelector={getMediaLibraryPopoverElement}
      includeOrganizationIdOnAsset={includeOrganizationIdOnAsset}
      includeInMediaLibrary={includeInMediaLibrary}
    />
  )

  if (!canViewAssets) return fileDropZone

  return (
    <>
      {fileDropZone}
      <div className={classes.header}>
        <div className={classes.lhsHeader}>
          <DiscoTableSearchInput
            testid={"MediaLibraryFileFields"}
            onChange={handleSearchChange}
          />
        </div>

        <div className={classes.rhsHeader}>
          <DiscoDropdown
            testid={"MediaLibraryFileFields.media-select"}
            menuClasses={{
              paper: classes.fileTypeDropdownPaper,
            }}
            menuButton={(btnProps) => (
              <DiscoButton
                {...btnProps}
                data-testid={"MediaLibraryFileFields.media-select.header-button"}
                className={classes.dropdownButton}
              >
                {toolbarState.fileTypeOption.title}
              </DiscoButton>
            )}
          >
            {dropdownOptions.map((option) => {
              return (
                <DiscoDropdownItem
                  key={option.type}
                  title={option.title}
                  testid={`MediaLibraryFileFields.media-select.item-${option.title}`}
                  onClick={() => handleFileTypeSelect(option)}
                  className={classes.dropdownFileType}
                />
              )
            })}
          </DiscoDropdown>
          <DiscoTableSortDropdown
            options={ORDER_BY_OPTIONS}
            onOptionSelect={handleFileTypeSort}
            toolbarState={toolbarState}
          />

          <DiscoTabs
            tabClassname={classes.viewTypeSelectTab}
            className={classes.viewTypeSelect}
            testid={"MediaLibraryFileFields.mediaView-select"}
            tabVariant={"grey-track"}
            routes={[
              {
                label: "",
                leftIcon: "grid",
                testid: "grid",
                onClick: () => {
                  handleViewChange("grid")
                },
                active: viewType === "grid",
              },
              {
                label: "",
                leftIcon: "list",
                testid: "list",
                onClick: () => {
                  handleViewChange("list")
                },
                active: viewType === "list",
              },
            ]}
          />
        </div>
      </div>

      {showImageOption && isImageAllowed && (
        <MediaFileListTemplate
          onClose={onClose}
          onMediaSelect={onMediaSelect}
          toolbarState={toolbarState}
          fileType={"image"}
        />
      )}
      {showVideoOption && isVideoAllowed && (
        <MediaFileListTemplate
          onClose={onClose}
          onMediaSelect={onMediaSelect}
          toolbarState={toolbarState}
          fileType={"video"}
        />
      )}
      {showDocumentOption && isDocumentAllowed && (
        <MediaFileListTemplate
          onClose={onClose}
          onMediaSelect={onMediaSelect}
          toolbarState={toolbarState}
          fileType={"document"}
          allowedMimeTypes={allowedMimeTypes}
        />
      )}
    </>
  )

  function handleSearchChange(value: string) {
    setToolbarState({ ...toolbarState, search: value })
  }

  function handleFileTypeSelect(value: SelectedFileTypeOption) {
    setToolbarState({
      ...toolbarState,
      fileTypeOption: value,
    })
  }

  function handleFileTypeSort(
    sortOption: DiscoTableSortOption,
    direction: DiscoTableSortDirection
  ) {
    if (sortOption) {
      setToolbarState({
        ...toolbarState,
        sort: {
          id: sortOption.value,
          order: sortOption.context!.order,
          title: sortOption.title,
        },
        direction,
      })
    }
  }
  function handleViewChange(value: string) {
    setToolbarState({ ...toolbarState, viewType: value })
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    flexDirection: "row",
    display: "flex",
    marginTop: theme.spacing(1.5),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: theme.spacing(1.5),
    },
  },
  fileDropzone: {
    height: "190px",
  },
  lhsHeader: {
    width: "100%",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      marginBottom: theme.spacing(1.5),
    },
  },
  rhsHeader: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      display: "flex",
      alignContent: "flex-start",
      width: "100%",
      justifyContent: "flex-start",
      gap: theme.spacing(1),
    },
  },
  dropdownFileType: {
    width: "129px",
    marginTop: theme.spacing(1),
  },
  dropdownButton: {
    minWidth: "130px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
  fileTypeDropdownPaper: {
    width: "140px",
    marginTop: theme.spacing(0.5),
  },
  viewTypeSelect: {
    borderRadius: theme.measure.borderRadius.medium,
    height: "40px",
    width: "108px",
    padding: theme.spacing(0.5),
    marginBottom: 0,
  },
  viewTypeSelectTab: {
    borderRadius: theme.measure.borderRadius.medium,
    height: "32px",
    width: "48px",
    margin: 0,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}))

export default MediaLibraryFileFields
