import { useLabels } from "@/core/context/LabelsContext"
import { DiscoAlert } from "@disco-ui"

function MemberOnboardingSlackTestMemberStepsAlert() {
  const labels = useLabels()
  return (
    <DiscoAlert
      severity={"info"}
      message={`${labels.organization_member.plural} with valid email addresses will receive an email invitation. As a test member, you won't receive one. To complete this onboarding step, click the "Complete and Continue" button.`}
    />
  )
}

export default MemberOnboardingSlackTestMemberStepsAlert
