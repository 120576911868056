/**
 * @generated SignedSource<<4750c63c4fdb538e0a5e4f533f7eda4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMemberOnboardingFormStoreFragment$data = {
  readonly id: string;
  readonly viewerHasCompleted: boolean;
  readonly " $fragmentType": "useMemberOnboardingFormStoreFragment";
};
export type useMemberOnboardingFormStoreFragment$key = {
  readonly " $data"?: useMemberOnboardingFormStoreFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMemberOnboardingFormStoreFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasCompleted",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "aeefaa32d97d596898de0e53580ca3e7";

export default node;
