/**
 * @generated SignedSource<<e12188d14f54e33aacf262375b0d0aff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCommunityAgreementFragment$data = {
  readonly content: {
    readonly id: string;
    readonly richEditorDescriptionContent: string | null;
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly id: string;
  readonly module: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment">;
  readonly " $fragmentType": "MemberOnboardingCommunityAgreementFragment";
};
export type MemberOnboardingCommunityAgreementFragment$key = {
  readonly " $data"?: MemberOnboardingCommunityAgreementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCommunityAgreementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingCommunityAgreementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "richEditorDescriptionContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "239863d487f960ea706c30eba42dc85d";

export default node;
