/**
 * @generated SignedSource<<b0ffba35e413a57eb78454b2ad45f168>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingGenericTaskFragment$data = {
  readonly content: {
    readonly systemTaskKind: ContentSystemTaskKind | null;
    readonly type: ContentType;
    readonly " $fragmentSpreads": FragmentRefs<"InlineContentDescriptionSectionFragment" | "InlineContentHeroSectionFragment">;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment">;
  readonly " $fragmentType": "MemberOnboardingGenericTaskFragment";
};
export type MemberOnboardingGenericTaskFragment$key = {
  readonly " $data"?: MemberOnboardingGenericTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingGenericTaskFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingGenericTaskFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentHeroSectionFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentDescriptionSectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "1ef231e64a38c52b2078369c3e9c2d08";

export default node;
