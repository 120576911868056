import FormStore from "@/core/form/store/FormStore"
import {
  GenerateAiImageInput,
  GenerateAiImageStyle,
  GenerateImageViewMutation,
} from "@/media/generate/__generated__/GenerateImageViewMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AiInput from "@components/ai/AIInput"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import Form from "@components/form/Form"
import { DiscoFormControl, DiscoIcon, DiscoIconButton, DiscoSelect } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { observer } from "mobx-react-lite"

const PROMPT_SUGGESTIONS = [
  "Whimsical painting of an enchanted forest with mythical creatures, vibrant colors, and intricate details",
  "Create a logo for a community named 'Designer Learner Academy'",
  "Create an infographic about 'The Evolution of the Internet'",
  "Create a prototype design for a new 'solar-powered laptop'",
  "A close-up macro shot of morning dew on a book, with the focus on the intricate patterns and water droplets, giving it an ethereal quality, high resolution.",
  "A cubist painting of a still life with geometric shapes, fragmented forms, and multiple perspectives, inspired by the works of Pablo Picasso and Georges Braque, using a muted color palette.",
  "Create an image for a social media campaign promoting a 'sustainable fashion brand community'",
  "A tranquil Zen garden with cherry blossom trees, a small bridge, and koi fish swimming in a pond",
  "An abstract expressionist painting using bold brush strokes, dripping paint, and vibrant colors, reflecting the inner emotions and turmoil, inspired by the works of Jackson Pollock.",
  "A close-up shot of a raindrop about to fall from a leaf, with a focus on reflection and the vibrant greenery around it, creating a fresh and pure atmosphere.",
  "A diverse group of students engaged in a virtual class, each connecting from their own devices, fostering a sense of digital collaboration.",
  "A metaphorical representation of growth through online education, with a virtual plant breaking through the screen to symbolize continuous learning.",
  "A close-up of hands using advanced devices in a remote setting, emphasizing the seamless integration of technology into virtual tasks.",
]

interface Props {
  form: FormStore<GenerateAiImageInput, GenerateImageViewMutation>
  handleSubmit: () => void
}

function GenerateImageForm(props: Props) {
  const { form, handleSubmit } = props
  const classes = useStyles()
  const theme = useTheme()
  const hasEntitlement = useHasEntitlement("ai_image_generation")

  return (
    <Form
      id={"GenerateImageView.form"}
      onSubmit={handleSubmit}
      isSubmitAllowed={hasEntitlement}
    >
      <div className={classes.formControl}>
        <DiscoFormControl
          error={Boolean(form.errorsByField.prompt)}
          errorMessages={form.errorsByField.prompt}
          label={" "}
          entitlement={"ai_image_generation"}
          marginTop={2.5}
          marginBottom={2.5}
        >
          <WithEntitlement entitlement={"ai_image_generation"} hideTooltip>
            {({ hasEntitlement: hasEntitlementInner }) => (
              <AiInput
                error={Boolean(form.errorsByField.prompt)}
                value={form.state.prompt}
                onChange={(e: any) => (form.state.prompt = e.target.value)}
                disabled={!hasEntitlementInner}
                placeholder={"Describe the image you want to create"}
                endAdornment={
                  <DiscoIconButton
                    onClick={setRandomPrompt}
                    color={theme.palette.groovy.neutral[400]}
                    className={classes.iconButton}
                    tooltip={"Inspire me with a random prompt."}
                    disabled={!hasEntitlementInner}
                  >
                    <DiscoIcon icon={"sparkles"} />
                  </DiscoIconButton>
                }
              />
            )}
          </WithEntitlement>
        </DiscoFormControl>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs={12} md={9}>
            <DiscoFormControl
              error={Boolean(form.errorsByField.style)}
              errorMessages={form.errorsByField.style}
            >
              <DiscoSelect
                testid={"GenerateImageForm.style-select"}
                autoComplete={false}
                disableClearable
                options={[
                  {
                    title: "Style: Auto",
                    value: "auto" as GenerateAiImageStyle,
                    subtitle: "Automatically selects the best style for your prompt",
                  },
                  {
                    title: "General",
                    value: "general" as GenerateAiImageStyle,
                    subtitle:
                      "Ideal for creating artistic works such as abstract paintings, pencil sketches, computer-manipulated images, and much more.",
                  },
                  {
                    title: "Realistic",
                    value: "realistic" as GenerateAiImageStyle,
                    subtitle:
                      "Primarily used when you want real-life photographic images.",
                  },
                  {
                    title: "Design",
                    value: "design" as GenerateAiImageStyle,
                    subtitle:
                      "Can create logos, menus, and much more. Essentially, anything related to graphic design.",
                  },
                  {
                    title: "3D Render",
                    value: "render_3d" as GenerateAiImageStyle,
                    subtitle:
                      "Perfect for generating images of characters, various objects, and other creations with a 3D visual appeal.",
                  },
                ]}
                value={form.state.style}
                onChange={(v) => {
                  if (v) form.state.style = v
                }}
                className={classes.styleSelect}
                disabled={!hasEntitlement}
                customSelectProps={{
                  MenuProps: {
                    classes: {
                      paper: classes.paper,
                    },
                  },
                }}
              />
            </DiscoFormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <WithEntitlement entitlement={"ai_image_generation"}>
              {({ hasEntitlement: hasEntitlementInner }) => (
                <Form.SubmitButton
                  id={"GenerateImageView.form.submit-button"}
                  testid={"GenerateImageView.form.submit-button"}
                  className={classes.generateButton}
                  form={form}
                  onClick={handleSubmit}
                  disabled={!hasEntitlementInner}
                  leftIcon={"magic-wand"}
                >
                  {"Generate"}
                </Form.SubmitButton>
              )}
            </WithEntitlement>
          </Grid>
        </Grid>
      </div>
    </Form>
  )
  function setRandomPrompt() {
    form.state.prompt =
      PROMPT_SUGGESTIONS[Math.floor(Math.random() * PROMPT_SUGGESTIONS.length)]
  }
}

const useStyles = makeUseStyles((theme) => ({
  formControl: {
    display: "flex",
    flexDirection: "column",
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight,
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  generateButton: {
    alignSelf: "flex-end",
    width: "100%",
    background: theme.palette.aiGradient.aiDark,
    marginBottom: 12,
  },
  styleSelect: {
    alignSelf: "flex-end",
    minWidth: "120px",
    border: `1.5px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[400]
        : theme.palette.groovy.neutral[200]
    }`,
    borderRadius: theme.measure.borderRadius.big,
  },
  paper: {
    width: "300px",
  },
  iconButton: {
    padding: theme.spacing(0, 1, 0, 1),
  },
}))

export default observer(GenerateImageForm)
