/**
 * @generated SignedSource<<933474d8104470186fa57af78409fca4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateInvitationFormCSVValidationMutation$variables = {
  csvUrl: string;
  productId?: string | null;
};
export type CreateInvitationFormCSVValidationMutation$data = {
  readonly validateCSV: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly rows: ReadonlyArray<{
      readonly email: {
        readonly error: string | null;
        readonly value: string | null;
      };
      readonly firstName: {
        readonly error: string | null;
        readonly value: string | null;
      } | null;
      readonly group: {
        readonly error: string | null;
        readonly value: string | null;
      } | null;
      readonly hasInvite: {
        readonly error: string | null;
        readonly value: boolean | null;
      } | null;
      readonly lastName: {
        readonly error: string | null;
        readonly value: string | null;
      } | null;
    }> | null;
    readonly totalRows: number | null;
    readonly validRows: number | null;
  };
};
export type CreateInvitationFormCSVValidationMutation = {
  response: CreateInvitationFormCSVValidationMutation$data;
  variables: CreateInvitationFormCSVValidationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "csvUrl"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "error",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "csvUrl",
        "variableName": "csvUrl"
      },
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      }
    ],
    "concreteType": "ValidatedCSVResponse",
    "kind": "LinkedField",
    "name": "validateCSV",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParsedInvitationCsvRow",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CsvCellValue",
            "kind": "LinkedField",
            "name": "email",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CsvCellValue",
            "kind": "LinkedField",
            "name": "firstName",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CsvCellValue",
            "kind": "LinkedField",
            "name": "lastName",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CsvCellValue",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CsvBooleanCellValue",
            "kind": "LinkedField",
            "name": "hasInvite",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalRows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validRows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvitationFormCSVValidationMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvitationFormCSVValidationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "99820ba7f129a547c5b45baaefc08d9e",
    "id": null,
    "metadata": {},
    "name": "CreateInvitationFormCSVValidationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateInvitationFormCSVValidationMutation(\n  $csvUrl: String!\n  $productId: ID\n) {\n  validateCSV(csvUrl: $csvUrl, productId: $productId) {\n    rows {\n      email {\n        value\n        error\n      }\n      firstName {\n        value\n        error\n      }\n      lastName {\n        value\n        error\n      }\n      group {\n        value\n        error\n      }\n      hasInvite {\n        value\n        error\n      }\n    }\n    totalRows\n    validRows\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88bddf62114fab7c287574e2fee87849";

export default node;
