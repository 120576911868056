/**
 * @generated SignedSource<<61df59cf9e2b00fb0d8a79b0c9ef8402>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type InvitationOption = {
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  memberGroupIds?: ReadonlyArray<string> | null;
};
export type CreateInvitationForm_pendingInvitationCountQuery$variables = {
  organizationId: string;
  organizationRole?: OrganizationRole | null;
  productId?: string | null;
  productRole?: ProductRole | null;
  users: ReadonlyArray<InvitationOption>;
};
export type CreateInvitationForm_pendingInvitationCountQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly pendingInvitationsCount?: number;
  } | null;
};
export type CreateInvitationForm_pendingInvitationCountQuery = {
  response: CreateInvitationForm_pendingInvitationCountQuery$data;
  variables: CreateInvitationForm_pendingInvitationCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationRole"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productRole"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "users"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "organizationRole",
      "variableName": "organizationRole"
    },
    {
      "kind": "Variable",
      "name": "productId",
      "variableName": "productId"
    },
    {
      "kind": "Variable",
      "name": "productRole",
      "variableName": "productRole"
    },
    {
      "kind": "Variable",
      "name": "users",
      "variableName": "users"
    }
  ],
  "kind": "ScalarField",
  "name": "pendingInvitationsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvitationForm_pendingInvitationCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateInvitationForm_pendingInvitationCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb3a6fb22b433e1285cb2c44db797b5b",
    "id": null,
    "metadata": {},
    "name": "CreateInvitationForm_pendingInvitationCountQuery",
    "operationKind": "query",
    "text": "query CreateInvitationForm_pendingInvitationCountQuery(\n  $organizationId: ID!\n  $users: [InvitationOption!]!\n  $productId: ID\n  $organizationRole: OrganizationRole\n  $productRole: ProductRole\n) {\n  node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      id\n      pendingInvitationsCount(users: $users, productId: $productId, organizationRole: $organizationRole, productRole: $productRole)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "790115f5a226c8ee65f2cfcab53d8c80";

export default node;
