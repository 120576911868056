/**
 * @generated SignedSource<<68454d3a1704615efa31e642f10b9bb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeleteMeetingProviderFormFragment$data = {
  readonly id: string;
  readonly occurrences: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly datetimeRange: ReadonlyArray<string>;
        readonly id: string;
        readonly name: string | null;
        readonly product: {
          readonly slug: string;
          readonly type: ProductType;
        };
      };
    }>;
  };
  readonly zoomConnection: {
    readonly email: string | null;
  };
  readonly " $fragmentType": "DeleteMeetingProviderFormFragment";
};
export type DeleteMeetingProviderFormFragment$key = {
  readonly " $data"?: DeleteMeetingProviderFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteMeetingProviderFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./DeleteMeetingProviderFormRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "DeleteMeetingProviderFormFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "upcomingOnly",
          "value": true
        }
      ],
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "occurrences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "datetimeRange",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "occurrences(upcomingOnly:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ZoomConnection",
      "kind": "LinkedField",
      "name": "zoomConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingProvider",
  "abstractKey": null
};
})();

(node as any).hash = "34c035d16d39a9eceec573109336dc12";

export default node;
