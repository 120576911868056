/**
 * @generated SignedSource<<84e704d2ed80986300dde2731172d0b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CompleteMemberOnboardingItemInput = {
  contentCompletionInput: ContentCompletionInput;
  customProfileFieldInput?: CustomProfileFieldInput | null;
};
export type ContentCompletionInput = {
  contentUsageId: string;
  membershipId: string;
};
export type CustomProfileFieldInput = {
  body?: string | null;
  isSkipped?: boolean | null;
  selectedOptions?: ReadonlyArray<string> | null;
};
export type useMemberOnboardingFormStoreMutation$variables = {
  input: CompleteMemberOnboardingItemInput;
};
export type useMemberOnboardingFormStoreMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly contentUsage: {
        readonly id: string;
        readonly viewerHasCompleted: boolean;
      };
      readonly id: string;
    } | null;
  };
};
export type useMemberOnboardingFormStoreMutation = {
  response: useMemberOnboardingFormStoreMutation$data;
  variables: useMemberOnboardingFormStoreMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompleteMemberOnboardingItemResponse",
    "kind": "LinkedField",
    "name": "completeMemberOnboardingItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentCompletion",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentUsage",
            "kind": "LinkedField",
            "name": "contentUsage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerHasCompleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMemberOnboardingFormStoreMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMemberOnboardingFormStoreMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0760dbb752ecd5d39e8e1198872555bd",
    "id": null,
    "metadata": {},
    "name": "useMemberOnboardingFormStoreMutation",
    "operationKind": "mutation",
    "text": "mutation useMemberOnboardingFormStoreMutation(\n  $input: CompleteMemberOnboardingItemInput!\n) {\n  response: completeMemberOnboardingItem(input: $input) {\n    node {\n      id\n      contentUsage {\n        id\n        viewerHasCompleted\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f0574350b256a2e407205f2fb48dc38";

export default node;
