/**
 * @generated SignedSource<<011556ba49c2907481ebea006e5e0267>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvitationFormCSVPreview_productFragment$data = {
  readonly slug: string;
  readonly type: ProductType;
  readonly " $fragmentType": "InvitationFormCSVPreview_productFragment";
};
export type InvitationFormCSVPreview_productFragment$key = {
  readonly " $data"?: InvitationFormCSVPreview_productFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormCSVPreview_productFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormCSVPreview_productFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "909bda73375656d4658c60a26a6517c4";

export default node;
