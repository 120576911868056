import { useAuthUser } from "@/core/context/AuthUserContext"
import MemberOnboardingButtons, {
  MemberOnboardingButtonsProps,
} from "@/member-onboarding/buttons/MemberOnboardingButtons"
import useViewersSlackConnection from "@/member-onboarding/hooks/useViewersSlackConnection"
import MemberOnboardingContentBodyWrapper from "@/member-onboarding/MemberOnboardingContentBodyWrapper"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import MemberOnboardingSlackMobileCarousel from "@/member-onboarding/slack-flow/MemberOnboardingSlackMobileCarousel"
import MemberOnboardingSlackRefreshInfo from "@/member-onboarding/slack-flow/MemberOnboardingSlackRefreshInfo"
import MemberOnboardingSlackTabs, {
  MemberOnboardingSlackTab,
} from "@/member-onboarding/slack-flow/MemberOnboardingSlackTabs"
import MemberOnboardingSlackTestMemberStepsAlert from "@/member-onboarding/slack-flow/MemberOnboardingSlackTestMemberStepsAlert"
import Browser from "@/member-onboarding/slack-flow/validate/Browser"
import Desktop from "@/member-onboarding/slack-flow/validate/Desktop"
import Mobile from "@/member-onboarding/slack-flow/validate/Mobile"
import { MemberOnboardingSlackValidateConnectionFragment$key } from "@/member-onboarding/slack-flow/validate/__generated__/MemberOnboardingSlackValidateConnectionFragment.graphql"
import SlackValidateConnectionMobile1 from "@assets/images/member-onboarding/slack-validate-connection-mobile-1.png"
import SlackValidateConnectionMobile2 from "@assets/images/member-onboarding/slack-validate-connection-mobile-2.png"
import SlackValidateConnection from "@assets/images/member-onboarding/slack-validate-connection.png"
import { DiscoLink } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import useInterval from "@utils/hook/useInterval"
import useIsMobileAgent from "@utils/hook/useIsMobileAgent"
import { TestIDProps } from "@utils/typeUtils"
import { useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

const MOBILE_IMAGES = [SlackValidateConnectionMobile1, SlackValidateConnectionMobile2]

type MemberOnboardingSlackValidateConnectionProps = TestIDProps & {
  onSubmit: MemberOnboardingButtonsProps["onSubmit"]
  onPrevious: MemberOnboardingButtonsProps["customPreviousAction"]
  usageKey: MemberOnboardingSlackValidateConnectionFragment$key
  disableNext?: boolean
}

function MemberOnboardingSlackValidateConnection({
  testid = "MemberOnboardingSlackValidateConnection",
  onSubmit,
  onPrevious,
  usageKey,
  disableNext,
}: MemberOnboardingSlackValidateConnectionProps) {
  const { isImpersonatingTestUser } = useAuthUser()
  const isMobileAgent = useIsMobileAgent()
  const [tab, setTab] = useState<MemberOnboardingSlackTab>(
    isMobileAgent ? "mobile" : "desktop"
  )
  const { refetch } = useViewersSlackConnection()
  const ref = useRef<HTMLDivElement>(null)

  // Poll to see when the user has a slackUserId
  useInterval(
    () => {
      refetch()
    },
    1000 * 5 // 5 seconds
  )

  const usage = useFragment<MemberOnboardingSlackValidateConnectionFragment$key>(
    graphql`
      fragment MemberOnboardingSlackValidateConnectionFragment on ContentUsage {
        ...MemberOnboardingBanner_SlackWorkspaceFragment @relay(mask: false)
      }
    `,
    usageKey
  )
  const { workspace } = usage?.content?.organization?.slack || {}

  return (
    <MemberOnboardingContentBodyWrapper>
      <MemberOnboardingContentHeader
        testid={testid}
        customContent={{
          name: "Perfect, let's validate your connection!",
          description: (
            <>
              {`Find the Disco App inside the `}
              {workspace?.name ? (
                <DiscoLink
                  href={workspace?.url || ""}
                  style={{ fontSize: "inherit", lineHeight: "inherit" }}
                >{`${workspace.name} `}</DiscoLink>
              ) : null}
              {"Slack workspace and connect from the app home screen."}
            </>
          ),
        }}
      />

      {isImpersonatingTestUser ? (
        <MemberOnboardingSlackTestMemberStepsAlert />
      ) : (
        <MemberOnboardingSlackRefreshInfo />
      )}

      {isMobileAgent ? (
        <MemberOnboardingSlackMobileCarousel images={MOBILE_IMAGES} />
      ) : (
        <DiscoImage src={SlackValidateConnection} />
      )}

      <MemberOnboardingButtons
        nextTitle={isImpersonatingTestUser ? "Complete and Continue" : "Continue"}
        customActionTitle={"See instructions"}
        onCustomAction={scrollToInstructions}
        onSubmit={onSubmit}
        customPreviousAction={onPrevious}
        disableNext={disableNext}
      />

      <MemberOnboardingSlackTabs
        tab={tab}
        setTab={setTab}
        title={"Connect Slack with the Disco Slack App"}
        content={{
          desktop: <Desktop />,
          browser: <Browser />,
          mobile: <Mobile />,
        }}
      />
      <div ref={ref} style={{ height: 1, width: "100%" }} />
    </MemberOnboardingContentBodyWrapper>
  )

  function scrollToInstructions() {
    ref.current?.scrollIntoView({ behavior: "smooth" })
    return null
  }
}

export default MemberOnboardingSlackValidateConnection
