/**
 * @generated SignedSource<<7880afa0ad3cd34020dc8885d629720e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AssetFileType = "document" | "image" | "subtitle" | "unknown" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MediaListItemFragment$data = {
  readonly createdAt: string;
  readonly createdByUser: {
    readonly fullName: string;
  } | null;
  readonly fileType: AssetFileType;
  readonly id: string;
  readonly mimeType: string;
  readonly name: string;
  readonly sizeBytes: number;
  readonly url: string;
  readonly " $fragmentType": "MediaListItemFragment";
};
export type MediaListItemFragment$key = {
  readonly " $data"?: MediaListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sizeBytes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdByUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": null
};

(node as any).hash = "6786a02e5d2c99d92853ae9416873394";

export default node;
