import { MemberOnboardingButtonsProps } from "@/member-onboarding/buttons/MemberOnboardingButtons"
import MemberOnboardingButtonsContainer from "@/member-onboarding/buttons/MemberOnboardingButtonsContainer"
import MemberOnboardingPreviousButton from "@/member-onboarding/buttons/MemberOnboardingPreviousButton"
import MemberOnboardingContentHeader from "@/member-onboarding/MemberOnboardingContentHeader"
import MemberOnboardingSlackRequestInvite from "@/member-onboarding/slack-flow/invite/MemberOnboardingSlackRequestInvite"
import MemberOnboardingSlackValidateConnection from "@/member-onboarding/slack-flow/validate/MemberOnboardingSlackValidateConnection"
import { MemberOnboardingSlackManualConnectionStepsFragment$key } from "@/member-onboarding/slack-flow/__generated__/MemberOnboardingSlackManualConnectionStepsFragment.graphql"
import { MemberOnboardingBanner_SlackWorkspaceFragment$data } from "@/member-onboarding/__generated__/MemberOnboardingBanner_SlackWorkspaceFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoCheckbox, DiscoLink } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup } from "@material-ui/core"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

type MemberOnboardingSlackManualConnectionStepsProps = {
  onSkip: VoidFunction
  onSubmit: MemberOnboardingButtonsProps["onSubmit"]
  usageKey: MemberOnboardingSlackManualConnectionStepsFragment$key
  disableNext?: boolean
}

type Options = "validate-connection" | "send-invitation" | null

function MemberOnboardingSlackManualConnectionSteps({
  onSubmit,
  onSkip,
  usageKey,
  disableNext,
}: MemberOnboardingSlackManualConnectionStepsProps) {
  const [selectedOption, setSelectedOption] = useState<Options>(null)

  const usage = useFragment<MemberOnboardingSlackManualConnectionStepsFragment$key>(
    graphql`
      fragment MemberOnboardingSlackManualConnectionStepsFragment on ContentUsage {
        ...MemberOnboardingBanner_SlackWorkspaceFragment @relay(mask: false)
        ...MemberOnboardingSlackValidateConnectionFragment
        ...MemberOnboardingSlackRequestInviteFragment
      }
    `,
    usageKey
  )

  switch (selectedOption) {
    case "validate-connection":
      return (
        <MemberOnboardingSlackValidateConnection
          onSubmit={onSubmit}
          onPrevious={onPrevious}
          usageKey={usage}
          disableNext={disableNext}
        />
      )
    case "send-invitation":
      return (
        <MemberOnboardingSlackRequestInvite
          onSkip={onSkip}
          onPrevious={onPrevious}
          usageKey={usage}
        />
      )
    default:
      return (
        <OptionSelector
          setSelectedOption={setSelectedOption}
          workspace={usage?.content?.organization?.slack?.workspace}
        />
      )
  }

  function onPrevious() {
    setSelectedOption(null)
  }
}

type OptionSelectorProps = {
  setSelectedOption: React.Dispatch<React.SetStateAction<Options>>
  workspace?: NonNullable<
    MemberOnboardingBanner_SlackWorkspaceFragment$data["content"]["organization"]["slack"]
  >["workspace"]
}

function OptionSelector({ setSelectedOption, workspace }: OptionSelectorProps) {
  const [_selectedOption, _setSelectedOption] = useState<Options>("send-invitation")

  const classes = useStyles()

  return (
    <>
      <MemberOnboardingContentHeader
        customContent={{
          name: (
            <>
              {`Let's get you set-up with `}
              {workspace?.name ? (
                <>
                  {"the "}
                  <DiscoLink
                    href={workspace.url || ""}
                    style={{ fontSize: "inherit", lineHeight: "inherit" }}
                  >
                    {`${workspace.name} `}
                  </DiscoLink>
                </>
              ) : (
                ""
              )}
              {"Slack"}
            </>
          ),
          description: `Are you in ${
            workspace?.name ? `the ${workspace.name}` : ""
          } Slack workspace?`,
        }}
      />
      <RadioGroup
        onChange={(e) => _setSelectedOption(e.target.value as Options)}
        value={_selectedOption}
        className={classes.group}
      >
        <DiscoRadioBox
          classes={{ radioRoot: classes.radioRoot }}
          variant={"compact"}
          icon={
            <DiscoCheckbox
              checked={_selectedOption === "send-invitation"}
              onClick={() => _setSelectedOption("send-invitation")}
              label={""}
              classes={{ root: classes.checkboxRoot }}
            />
          }
          value={"send-invitation"}
          title={"No - I am not part of this Slack workspace"}
        />
        <DiscoRadioBox
          classes={{ radioRoot: classes.radioRoot }}
          variant={"compact"}
          icon={
            <DiscoCheckbox
              checked={_selectedOption === "validate-connection"}
              onClick={() => _setSelectedOption("validate-connection")}
              label={""}
              classes={{ root: classes.checkboxRoot }}
            />
          }
          value={"validate-connection"}
          title={`Yes - I am in ${
            workspace?.name ? `the ${workspace.name} ` : ""
          }Slack 😊`}
        />
      </RadioGroup>
      <MemberOnboardingButtonsContainer>
        <DiscoButton onClick={() => setSelectedOption(_selectedOption)}>
          {"Continue"}
        </DiscoButton>
        <MemberOnboardingPreviousButton />
      </MemberOnboardingButtonsContainer>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  group: {
    gap: theme.spacing(2),
  },
  checkboxRoot: {
    marginRight: 0,
  },
  radioRoot: {
    padding: theme.spacing(1, 2),
  },
}))

export default MemberOnboardingSlackManualConnectionSteps
