import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CreateMeetingProviderFormState } from "@/meeting-provider/common/add/form/CreateMeetingProviderForm"
import { CreateMeetingProviderFormFragment$data } from "@/meeting-provider/common/add/form/__generated__/CreateMeetingProviderFormFragment.graphql"
import { CreateMeetingProviderFormMutation } from "@/meeting-provider/common/add/form/__generated__/CreateMeetingProviderFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { DiscoCheckbox, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { FC } from "react"

type Props = {
  form: FormStore<CreateMeetingProviderFormState, CreateMeetingProviderFormMutation>
  connections: CreateMeetingProviderFormFragment$data["unlinkedZoomConnections"]
}

const CreateMeetingProviderFormFields: FC<Props> = ({ form, connections }) => {
  const availableConnections = Relay.connectionToArray(connections)
  const classes = useStyles()

  return (
    <>
      {availableConnections.map(({ id, email, firstName, lastName, picUrl }) => (
        <div key={id} className={classes.connectionItem}>
          <DiscoCheckbox
            data-testid={`CreateMeetingProviderForm.checkbox.${email}`}
            label={
              <div className={classes.details}>
                <UserAvatar
                  testid={"ZoomConnection"}
                  user={{
                    first_name: firstName,
                    last_name: lastName,
                    avatar: picUrl,
                    id,
                    is_test_user: false, // This is the user's zoom profile pic so don't ever show the test user UI
                  }}
                  size={24}
                />
                <DiscoText display={"inline"}>{email}</DiscoText>
              </div>
            }
            checked={form.state.selected![id]!}
            onChange={() => (form.state.selected![id] = !form.state.selected![id]!)}
          />
        </div>
      ))}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  connectionItem: {
    marginLeft: theme.spacing(1.5),
  },
  details: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
}))

export default observer(CreateMeetingProviderFormFields)
