/**
 * @generated SignedSource<<c692194ff410fe38281ca00b3cd130a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvitationFormCopyLinkField__Product$data = {
  readonly cover: string;
  readonly description: string | null;
  readonly id: string;
  readonly landingPage: {
    readonly metaDescription: string | null;
    readonly metaImageUrl: string | null;
    readonly metaTitle: string | null;
  } | null;
  readonly name: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly " $fragmentType": "InvitationFormCopyLinkField__Product";
};
export type InvitationFormCopyLinkField__Product$key = {
  readonly " $data"?: InvitationFormCopyLinkField__Product$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormCopyLinkField__Product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormCopyLinkField__Product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LandingPage",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "da98d49bb7218d571b2506bff11350ef";

export default node;
