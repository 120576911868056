/**
 * @generated SignedSource<<1d4ffed62052998369cb0e553a35edef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMeetingProviderButtonQuery$variables = {
  id: string;
};
export type CreateMeetingProviderButtonQuery$data = {
  readonly organization: {
    readonly viewerUnlinkedZoomConnections?: {
      readonly __id: string;
      readonly totalCount: number;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CreateMeetingProviderFormFragment">;
  } | null;
};
export type CreateMeetingProviderButtonQuery = {
  response: CreateMeetingProviderButtonQuery$data;
  variables: CreateMeetingProviderButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ZoomConnectionNodeConnection",
  "kind": "LinkedField",
  "name": "viewerUnlinkedZoomConnections",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMeetingProviderButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateMeetingProviderFormFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMeetingProviderButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "platform",
                    "value": "zoom"
                  }
                ],
                "concreteType": "MeetingProviderNodeConnection",
                "kind": "LinkedField",
                "name": "meetingProviders",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": "meetingProviders(platform:\"zoom\")"
              },
              {
                "alias": "unlinkedZoomConnections",
                "args": null,
                "concreteType": "ZoomConnectionNodeConnection",
                "kind": "LinkedField",
                "name": "viewerUnlinkedZoomConnections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ZoomConnectionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ZoomConnection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "picUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c88982a527b043dbfb05f6d447981ee",
    "id": null,
    "metadata": {},
    "name": "CreateMeetingProviderButtonQuery",
    "operationKind": "query",
    "text": "query CreateMeetingProviderButtonQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      viewerUnlinkedZoomConnections {\n        totalCount\n      }\n      ...CreateMeetingProviderFormFragment\n    }\n    id\n  }\n}\n\nfragment CreateMeetingProviderFormFragment on Organization {\n  id\n  name\n  meetingProviders(platform: zoom) {\n    totalCount\n  }\n  unlinkedZoomConnections: viewerUnlinkedZoomConnections {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        picUrl\n        email\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d87a7a9445137507b6d4877dd8718d3d";

export default node;
