import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import { InvitationFormCSVPreview_productFragment$key } from "@/invitation/create/form/fields/__generated__/InvitationFormCSVPreview_productFragment.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-fill-light.svg"
import XIcon from "@assets/disco/icons/color-icons/cross-fill-light.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoChip, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Table, TableCell, TableRow } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

interface Props {
  form: FormStore<CreateInvitationFormState>
  productKey?: InvitationFormCSVPreview_productFragment$key | null
}

function InvitationFormCSVPreview(props: Props) {
  const { form, productKey = null } = props
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()
  const [rowToRender, setRowToRender] = useState(0)
  const memberLabel = useLabel(form.state.productId ? "product_member" : "admin_member")
  const product = useFragment(
    graphql`
      fragment InvitationFormCSVPreview_productFragment on Product {
        slug
        type
      }
    `,
    productKey
  )

  const productLabel = useAdminProductLabel(product?.type ?? "course")

  if (!form.state.csvMetadata?.data) return null
  const validatedData = form.state.csvMetadata.data
  if (validatedData.length === 0) return null
  const header = ["Email", "First Name", "Last Name", "Group", "Previously Invited"]

  const inviteLink =
    activeOrganization?.primaryDomain +
    (product
      ? generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: product?.slug,
        })
      : ROUTE_NAMES.COMMUNITY.JOIN.ROOT)

  return (
    <>
      <div className={classes.wrapper}>
        <Table className={classes.table}>
          <TableRow classes={{ root: classes.header }}>
            {header.map((item) => (
              <TableCell key={item}>
                <DiscoText variant={"body-md-600"}>{item}</DiscoText>
              </TableCell>
            ))}
            {/** Empty cell for the checkmark column */}
            <TableCell />
          </TableRow>
          {validatedData
            .slice(0, rowToRender)
            .map(({ email, firstName, lastName, group, hasInvite }) => {
              const rowErrors = [
                email.error,
                firstName?.error,
                lastName?.error,
                form.state.productId && group?.error,
              ].filter(Boolean)

              const badRecord = Boolean(rowErrors.length)

              return (
                <React.Fragment key={email.value}>
                  <TableRow className={badRecord ? classes.badRecord : ""}>
                    <DiscoTooltip content={email.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        <DiscoText color={email.error ? "error.main" : undefined}>
                          {email.value}
                        </DiscoText>
                      </TableCell>
                    </DiscoTooltip>

                    <DiscoTooltip content={firstName?.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        <DiscoText
                          data-testid={`InvitationFormCSVPreview.${email.value}.firstName`}
                          color={firstName?.error ? "error.main" : undefined}
                        >
                          {firstName?.value || ""}
                        </DiscoText>
                      </TableCell>
                    </DiscoTooltip>

                    <DiscoTooltip content={lastName?.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        <DiscoText
                          data-testid={`InvitationFormCSVPreview.${email.value}.lastName`}
                          color={lastName?.error ? "error.main" : undefined}
                        >
                          {lastName?.value || ""}
                        </DiscoText>
                      </TableCell>
                    </DiscoTooltip>

                    <DiscoTooltip content={group?.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        <DiscoText
                          data-testid={`InvitationFormCSVPreview.${email.value}.group.${group?.value}`}
                          color={group?.error ? "error.main" : undefined}
                        >
                          {group?.value || ""}
                        </DiscoText>
                      </TableCell>
                    </DiscoTooltip>

                    <DiscoTooltip content={hasInvite?.error}>
                      <TableCell classes={{ root: classes.cell }}>
                        {hasInvite?.value ? (
                          <DiscoChip label={"Yes"} color={"yellow"} />
                        ) : (
                          <DiscoChip label={"No"} />
                        )}
                      </TableCell>
                    </DiscoTooltip>

                    <TableCell classes={{ root: classes.cell }}>
                      {badRecord ? (
                        <XIcon width={24} height={24} />
                      ) : (
                        <CheckmarkIcon width={24} height={24} />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={header.length + 1} className={classes.error}>
                      {rowErrors.map((err) => (
                        <DiscoText
                          key={err}
                          color={"groovy.red.500"}
                        >{`*${err}`}</DiscoText>
                      ))}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
        </Table>
        <DiscoScrolledIntoView
          onScrolledIntoView={() => setRowToRender((prev) => prev + 10)}
        />
      </div>
      {form.errorsByField.invalidRows ? (
        <DiscoAlert
          message={form.errorsByField.invalidRows[0]}
          severity={"error"}
          marginBottom={2}
        />
      ) : (
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Successfully imported ${validatedData.length} ${memberLabel.plural}! Those invited will receive an email with a link to the `}
          <DiscoLink
            to={inviteLink}
            data-testid={`InvitationFormCopyLinkField.registration-page.link`}
            target={"_blank"}
            textVariant={"body-sm"}
          >
            {"Registration Page"}
          </DiscoLink>
          {` for this ${product ? productLabel.singular : `Community`}.`}
        </DiscoText>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  table: {
    borderCollapse: "separate",
  },
  wrapper: {
    height: "100%",
    maxHeight: "280px",
    overflow: "auto",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  header: {
    "& td": {
      backgroundColor: theme.palette.groovy.neutral[100],
      "&:first-child": {
        borderTopLeftRadius: theme.measure.borderRadius.big,
        borderBottomLeftRadius: theme.measure.borderRadius.big,
      },
      "&:last-child": {
        borderTopRightRadius: theme.measure.borderRadius.big,
        borderBottomRightRadius: theme.measure.borderRadius.big,
      },
    },
  },
  cell: {
    padding: theme.spacing(1.5, 1),
  },
  badRecord: {
    "& td": {
      borderTop: `1.5px solid ${theme.palette.error.main}`,
      borderBottom: `1.5px solid ${theme.palette.error.main}`,
    },
    "& td:first-child": {
      borderLeft: `1.5px solid ${theme.palette.error.main}`,
      borderTopLeftRadius: theme.measure.borderRadius.big,
      borderBottomLeftRadius: theme.measure.borderRadius.big,
    },
    "& td:last-child": {
      borderRight: `1.5px solid ${theme.palette.error.main}`,
      borderTopRightRadius: theme.measure.borderRadius.big,
      borderBottomRightRadius: theme.measure.borderRadius.big,
    },
  },
  error: {
    padding: 0,
  },
}))

export default observer(InvitationFormCSVPreview)
