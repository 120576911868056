import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ProductMultiSelect from "@/experience/components/ProductMultiSelect"
import { CreateInvitationFormState } from "@/invitation/create/form/CreateInvitationForm"
import InvitationFormDiscountSelect from "@/invitation/create/form/fields/InvitationFormDiscountSelect"
import { InvitationFormMemberFieldsFragment$key } from "@/invitation/create/form/fields/__generated__/InvitationFormMemberFieldsFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { observable, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  form: FormStore<CreateInvitationFormState>
  productKey: InvitationFormMemberFieldsFragment$key | null
  testid?: string
}

function InvitationFormMemberFields(props: Props) {
  const { form, productKey, testid = "InvitationFormEmailsField" } = props
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()
  const memberLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  const product = useFragment<InvitationFormMemberFieldsFragment$key>(
    graphql`
      fragment InvitationFormMemberFieldsFragment on Product {
        id
        registrationType
        registrationPricing {
          basePrice
        }
        slug
      }
    `,
    productKey
  )

  const isPaid = Boolean(product?.registrationPricing?.basePrice)
  const canManageDiscounts = activeOrganization.viewerPermissions.has("discounts.manage")

  return (
    <>
      {/** Skip application toggle for product with application */}
      {product?.registrationType === "application" && (
        <DiscoFormControl>
          <DiscoSwitch
            data-testid={`${testid}.skip-application-toggle`}
            name={"toggle-skips-application"}
            checked={form.state.skipsApplication!}
            onChange={() => (form.state.skipsApplication = !form.state.skipsApplication)}
            label={"Allow those invited to bypass application"}
          />
        </DiscoFormControl>
      )}

      {product && isPaid && canManageDiscounts && (
        <DiscoSection className={classes.discountContainer}>
          <InvitationFormDiscountSelect form={form} />
        </DiscoSection>
      )}
      {!form.state.productId && (
        <>
          <DiscoFormControl>
            <DiscoSwitch
              data-testid={`${testid}.invite-to-experiences-toggle`}
              name={"toggle-invite-to-experiences"}
              checked={form.state.inviteToExperience!}
              onChange={() =>
                (form.state.inviteToExperience = !form.state.inviteToExperience)
              }
              label={
                <div className={classes.tooltipText}>
                  <DiscoText variant={"body-sm"} color={"text.secondary"} marginRight={1}>
                    {`Invite to ${experienceLabel.plural}`}
                  </DiscoText>
                  <DiscoTooltip
                    content={`Automatically send specific ${experienceLabel.singular} invitations to ${memberLabel.plural}. NOTE: ${experienceLabel.singular} invites are sent AFTER the ${memberLabel.singular} accepts the community invitation.`}
                    color={theme.palette.text.secondary}
                  />
                </div>
              }
            />
          </DiscoFormControl>
          {form.state.inviteToExperience && (
            <DiscoSection padding={2.5} paddingBottom={1} groovyDepths={"insideCard"}>
              <DiscoFormControl
                label={`Select ${experienceLabel.plural}`}
                errorMessages={form.errorsByField.inviteToProductIds}
              >
                <ProductMultiSelect
                  values={
                    form.state.inviteToProductIds
                      ? toJS(form.state.inviteToProductIds)
                      : []
                  }
                  onChange={(selected) => {
                    if (form.state.inviteToProductIds) {
                      form.state.inviteToProductIds.replace(selected)
                    } else {
                      form.state.inviteToProductIds = observable.array(selected)
                    }
                  }}
                  testid={`${testid}.ProductMultiSelect`}
                  disabled={!form.state.inviteToExperience}
                  hideAutoJoin={true}
                  hideNonPublic={false}
                  types={["course"]}
                />
              </DiscoFormControl>
            </DiscoSection>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  discountContainer: {
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  tooltipText: {
    display: "flex",
    alignItems: "center",
  },
}))

export default observer(InvitationFormMemberFields)
