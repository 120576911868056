/**
 * @generated SignedSource<<89e203b38ebb19e4aa0c3c107bce8b78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegistrationMembershipPlanDetailsFragment$data = {
  readonly cover: string;
  readonly id: string;
  readonly myCheckout: {
    readonly id: string;
  } | null;
  readonly richEditorCheckoutDescription: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanIncludedProductsFragment" | "RegistrationExperienceDetailsFormFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "RegistrationMembershipPlanDetailsFragment";
};
export type RegistrationMembershipPlanDetailsFragment$key = {
  readonly " $data"?: RegistrationMembershipPlanDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegistrationMembershipPlanDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideDrafts"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideNonPublic"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegistrationMembershipPlanDetailsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorCheckoutDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Checkout",
      "kind": "LinkedField",
      "name": "myCheckout",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegistrationExperienceDetailsFormFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "hideDrafts",
          "variableName": "hideDrafts"
        },
        {
          "kind": "Variable",
          "name": "hideNonPublic",
          "variableName": "hideNonPublic"
        }
      ],
      "kind": "FragmentSpread",
      "name": "MembershipPlanIncludedProductsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "02381b3fa16b00a01479cdd736b33353";

export default node;
