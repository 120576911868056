/**
 * @generated SignedSource<<5c36cba602543bc8ff2b403f1dd0450d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "annually" | "monthly" | "weekly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancelMembershipPlanButtonFragment$data = {
  readonly id: string;
  readonly isFreeMembership: boolean | null;
  readonly name: string;
  readonly pricing: {
    readonly frequency: PricingFrequency | null;
  } | null;
  readonly viewerCanCancelMembershipPlan: {
    readonly message: string;
    readonly value: boolean;
  };
  readonly " $fragmentType": "CancelMembershipPlanButtonFragment";
};
export type CancelMembershipPlanButtonFragment$key = {
  readonly " $data"?: CancelMembershipPlanButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelMembershipPlanButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelMembershipPlanButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFreeMembership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CancelMembershipPlan",
      "kind": "LinkedField",
      "name": "viewerCanCancelMembershipPlan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "28b87794dc5f7397bd8f73df2435b584";

export default node;
