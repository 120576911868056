import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { ExperienceSettingsFormStore } from "@/pricing/PricingFormFields"
import { UpdateMembershipBenefitInput } from "@/product/register/__generated__/RegistrationExperienceDetailsForm_PathwayMutation.graphql"
import { PricingKind } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"

/**
 * Format the update benefits object for a product
 */
export function formatUpdateMembershipBenefitInput(
  form: ExperienceSettingsFormStore | PathwaySettingsFormStore
): UpdateMembershipBenefitInput[] {
  const { pricingType: changedPricingType } = form.changedState

  const updateBenefits: UpdateMembershipBenefitInput[] = form.state.benefits.map((b) => {
    const mb = {
      id: b.id,
      membershipPlanId: b.membershipPlanId,
    }

    // If price has not changed, keep the same pricing, or if using
    // per-plan pricing, set individual pricings for each benefit
    if (!changedPricingType || changedPricingType === "per-plan") {
      return {
        ...mb,
        pricing: {
          amountCents: b.pricing.amountCents,
          kind: b.pricing.kind,
          frequency: b.pricing.frequency,
        },
      }
    }

    // If the pricing type has changed to one-time, set the same
    // pricing for all benefits, which is the first benefit's pricing
    if (changedPricingType === "paid") {
      const firstPricing = form.state.benefits[0].pricing
      return {
        ...mb,
        pricing: {
          amountCents: firstPricing.amountCents,
          kind: firstPricing.kind,
          frequency: firstPricing.frequency,
        },
      }
    }

    // Otherwise the pricing type is free
    return {
      ...mb,
      pricing: { amountCents: 0, kind: "free" as PricingKind, frequency: null },
    }
  })

  return updateBenefits
}
