/**
 * @generated SignedSource<<0b19347dd8d201c350e3512e7f4164bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "add_events_to_calendar" | "calendar_connection" | "community_agreement" | "complete_profile" | "connect_slack" | "custom_profile_field" | "default_profile_fields" | "download_mobile_app" | "onboarding_complete" | "onboarding_module" | "onboarding_welcome" | "slack_connection" | "slack_invite" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingCompleteFragment$data = {
  readonly content: {
    readonly ctaText: string | null;
    readonly id: string;
    readonly systemTaskKind: ContentSystemTaskKind | null;
    readonly type: ContentType;
    readonly " $fragmentSpreads": FragmentRefs<"InlineContentDescriptionSectionFragment">;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentHeaderFragment" | "useMemberOnboardingFormStoreFragment">;
  readonly " $fragmentType": "MemberOnboardingCompleteFragment";
};
export type MemberOnboardingCompleteFragment$key = {
  readonly " $data"?: MemberOnboardingCompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingCompleteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingCompleteFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaText",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentDescriptionSectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingContentHeaderFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "80e727ec6e50b916d956258aa07c8421";

export default node;
