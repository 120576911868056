/*
As of Babel 7.4.0, @babel/polyfill package has been deprecated in favor of directly including
- core-js/stable (to polyfill ECMAScript features)
- regenerator-runtime/runtime (needed to use transpiled generator functions):
*/

// Figtree Fontface
import loadControlApp from "@/control/ControlIndex"
import loadApp from "@/core/CoreIndex"
import "@fontsource/figtree/400.css"
import "@fontsource/figtree/500.css"
import "@fontsource/figtree/600.css"
import "@fontsource/figtree/700.css"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "stream-chat-react/dist/css/v2/index.css"

// Check if the domain is our management domain
if (window.location.origin.startsWith(CONTROL_DOMAIN)) {
  loadControlApp()
} else {
  loadApp()
}
